import "normalize.css";
import "./ProducerTemplate.scss";

import React, { FC, useContext, useEffect, useRef, useState } from "react";

import Layout from "../../components/Layout";
import SEO from "../../components/SEO";
import NavContext, {
  NavProvider,
} from "../../components/Layout/Nav/NavProvider";

import HomeSliderContact from "../../components/HomeSliderContact";
import Filters from "../../components/Filters";
import SectionHeader from "../../components/SectionHeader";
import Slider from "../../components/Slider";
import { t } from "i18next";
import { graphql } from "gatsby";
import { PageTemplateProps } from "../../constants/general";
import Project from "../../components/Project";
import { ProducerListMainContent } from "../../components/ProducerListMainContent";
import { getCurrentLanguage } from '../../i18n';

const PageTemplate: FC<PageTemplateProps> = ({ data, pageContext }) => {
  const [openFilters, setOpenFilters] = useState<boolean>(false);
  const { project, producer } = data;
  const currentSlug = pageContext.slug;
  const language = getCurrentLanguage();
  const pageTitle = `${t("producer.pageTitle")} Youplanet`;
  const description = `${t("producer.pageTitle")} Youplanet`;
  const alternativeUrls = pageContext.alternativeUrls;
  const producerListByLanguage = producer.nodes.filter(x => x.slug.current.includes(`${language}/`));

  const [isMobileActive, setIsMobileActive] = useState<boolean>(false);
  const { isMobile } = useContext(NavContext);
  const producerVideoRef = useRef<HTMLDivElement>();

  useEffect(() => {
    setIsMobileActive(isMobile());
  }, [isMobile]);

  const projectDistinct = () => {
    let list = [];
    const projectsList: any[] = project.nodes.filter((p) => !p.showInProject);


    producer.nodes.forEach((item) => {
      const duplicatedIndex = projectsList.findIndex((project) => {
        return project.name.trim() === item.name.trim();
      });

      if (duplicatedIndex >= 0) {
        projectsList.splice(duplicatedIndex, 1);
      }

      list = projectsList;
    });

    return list;
  };

  const list = [...producer.nodes, ...projectDistinct()]
    .filter(x => x && x.slug.current.includes(`${language}/`))
    .sort((a, b) => {
      if (a.position === null || b.position === null) {
        return 0;
      }
      return a.position - b.position;
    });

  const buttonShowFilterClickHandler = () => {
    setOpenFilters(true);
  };

  const buttonApplyFilterClickHandler = () => {
    setOpenFilters(false);
  };

  const getProducerCategories = (producerList: any[]) => {
    const producerCategories: { [key: string]: string } = {};

    producerList.forEach((item) => {
      const categories =
        item.producerCategories?.length > 0
          ? item.producerCategories
          : item.categories;
      categories.forEach((category) => {
        if (category) {
          producerCategories[category.slug.current] = category.name;
        }
      });
    });
    return producerCategories;
  };

  const filterOptions = getProducerCategories(list);

  const videoSection = () => {
    return (
      <div className="producer__video-wrapper">
        <div
          className={`producer__video ${isMobileActive ? "is-active" : ""}`}
          ref={producerVideoRef}
        >
          <Project
            videoId="3uuMzl7AXtI"
            isDetail
            autoPlay
            isMobileAutoplayActive={isMobileActive}
            defaultMuted
          />
        </div>
      </div>
    );
  };

  function pageContent() {
    const elements: JSX.Element[] = [
      videoSection(),
      <ProducerListMainContent
        list={list}
        onApplyFilter={buttonApplyFilterClickHandler}
        onShowFilter={buttonShowFilterClickHandler}
      />,
      <HomeSliderContact />,
    ];
    return elements.map((el, index) => <span key={`${index}-producerTemplate`}>{el}</span>);
  }

  return (
    <NavProvider>
      <Layout
        language={language}
        alternativeUrls={alternativeUrls}
        isHome={false}
      >
        <SEO
          title={pageTitle}
          description={description}
          lang={language}
          slug={currentSlug}
        />
        <SectionHeader h1={t("producer.h1")} inView={true} />
        <section className={`producer`}>
          <Filters
            options={filterOptions}
            opened={openFilters}
            applyButtonHandler={buttonApplyFilterClickHandler}
          />
          {isMobileActive ? (
            pageContent()
          ) : (
            <Slider hidePagination>{pageContent()}</Slider>
          )}
        </section>
      </Layout>
    </NavProvider>
  );
};

export default PageTemplate;

export const query = graphql`
  query ProducerTemplateQuery($lang: String) {
    project: allSanityProject(
      sort: { order: ASC, fields: [position] }
      filter: { i18n_lang: { in: [$lang, null] }, _id: { regex: "/^(?!drafts.).*/" } }
    ) {
      nodes {
        name
        brand
        youtubeId
        showInProject
        position
        slug {
          current
        }
        producerCategories: producerCategory {
          name
          slug {
            current
          }
        }
        categories: category {
          name
          slug {
            current
          }
        }
      }
    }
    producer: allSanityProducer(
      sort: { order: ASC, fields: [position] }
      filter: { i18n_lang: { in: [$lang, null] }, _id: { regex: "/^(?!drafts.).*/" } }
    ) {
      nodes {
        name
        brand
        youtubeId
        position
        slug {
          current
        }
        categories: category {
          name
          slug {
            current
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $lang } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
