import Flooxer from "../images/Producer/flooxer.svg";
import Mediapro from "../images/Producer/mediapro.svg";
import Tiktok from "../images/Producer/tiktok.svg";
import Twitch from "../images/Producer/twitch.svg";
import Ubeat_tv from "../images/Producer/ubeat_tv.svg";

export const partnewrsBrandList = [
  Flooxer,
  Mediapro,
  Tiktok,
  Twitch,
  Ubeat_tv,
  Flooxer,
  Mediapro,
  Tiktok,
  Twitch,
  Ubeat_tv,
];
