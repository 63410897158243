import React, { FC, useContext, useEffect, useRef, useState } from "react";
import Button from "../../components/Button";
import { getCurrentLanguage } from "../../i18n";

import AgencyClientsSlider from "../../components/AgencyClientsSlider";
import ProjectList from "../../components/ProjectList";
import { partnewrsBrandList } from "../../data/producerPartners";
import { t } from "i18next";
import { ProjectType } from "../../models/project.model";
import "./ProducerListMainContent.scss";
import NavContext from "../../components/Layout/Nav/NavProvider";
import { getSectionSlug, YouplanetSections } from "../../constants/general";

type ProducerListMainContentType = {
  onShowFilter: Function;
  onApplyFilter: Function;
  list: ProjectType[];
};
export const ProducerListMainContent: FC<ProducerListMainContentType> = (
  props
) => {
  const { onShowFilter, onApplyFilter, list } = props;
  const [isMobileActive, setIsMobileActive] = useState<boolean>(false);

  const { isMobile } = useContext(NavContext);
  const producerContentRef = useRef<HTMLDivElement>();

  useEffect(() => {
    setIsMobileActive(isMobile());
  }, [isMobile])

  const getFilterIndex = (producerList: any[]) => {
    const index: { [key: string]: string[] } = {};

    producerList.forEach((item) => {
      const categories =
        item.producerCategories?.length > 0
          ? item.producerCategories
          : item.categories;
      categories.forEach((category) => {
        if (!index[category.slug.current]) {
          index[category.slug.current] = [];
        }
        index[category.slug.current].push(item);
      });
    });
    return index;
  };

  return (
    <div
      ref={producerContentRef}
      className={`producer-list ${isMobileActive ? 'is-active' : "is-prev"}`}
    >
      <div className="producer-list__overlay" />
      <div className={`producer-list__content scrollable`}>
        <div className="producer-list__claim">
          <h2 className="producer-list__h2">{t("producer.h2")}</h2>
          <p className="producer-list__description">
            {t("producer.description")}
          </p>
        </div>
        <div className={`producer-list__button-show-filter`}>
          <Button clickHandler={() => onShowFilter()} uppercase={true}>
            {t("filters.button")}
          </Button>
        </div>
        <ProjectList filterIndex={getFilterIndex(list)} projects={list} disableScroll />
        <div className="producer-list__button-contact">
          <Button
            clickHandler={() => onApplyFilter()}
            uppercase
            isLink
            href={getSectionSlug(YouplanetSections.Contact, getCurrentLanguage())}
          >
            {t("producer.requestQuote")}
          </Button>
        </div>

        <div className="producer-list__clients">
          <AgencyClientsSlider
            description={t("producer.clientSliderTitle")}
            brandList={partnewrsBrandList}
          />
        </div>
      </div>
    </div>
  );
};
