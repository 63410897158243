import "./AgencyClientsSlider.scss";

import React, { FC, useEffect, useRef, useState, useContext } from "react"
import NavContext from '../Layout/Nav/NavProvider';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css/bundle";
import "swiper/css";
import { useInView } from '../../hooks/useInView';

export interface AgencyClientsSliderProps {
  h2?: string;
  description?: string;
  brandList: any[];
}

const AgencyClientsSlider: FC<AgencyClientsSliderProps> = ({ h2, description, brandList }) => {
  const clients = brandList;
  const sliderClients = [...clients, ...clients];

  const slider = useRef<HTMLDivElement>();
  const images = useRef<HTMLDivElement[]>([]);

  const [imagesLoaded, setImagesLoaded] = useState<number>(0);
  const [isMobileActive, setIsMobileActive] = useState<boolean>(false);

  const { isMobile } = useContext(NavContext);
  const clientRef = useRef<HTMLDivElement>();
  
  const inView = useInView(clientRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0.1,
  }, true);
  
  useEffect(() => {
    setIsMobileActive(isMobile());
  }, [isMobile])

  useEffect(() => {
    if (!slider.current || !images.current) {
      return;
    }
    
    const imagesTotalWidth = images.current.reduce((acc, img) => {
      return acc + img.getBoundingClientRect().width;
    }, 0);
    slider.current.style.setProperty('--animation-to', `-${imagesTotalWidth / 2}px`);
  }, [imagesLoaded]);

  return (
    <div ref={clientRef} className={`agency-clients ${isMobileActive && inView ? 'is-active' : ''}`}>
      {
        !h2 && !description ? '' :
        <div className="agency-clients__text">
          { h2 ? <h2 className="agency-clients__h2">{h2}</h2> : '' }
          { description ? <p className="agency-clients__description">{description}</p> : '' }
        </div>
      }      
      <div ref={slider} className="agency-clients__slider">
        <div className="agency-clients__slider__separator agency-clients__slider__separator--top"></div>
        <div className="agency-clients__slider__content">
        <Swiper
              allowTouchMove={false}
              slidesPerView={8}
              spaceBetween={0}
              speed={isMobileActive ? 1000 : 7000}
              loop={true}
              loopFillGroupWithBlank={true}
              autoplay={{
                delay: 0,
                waitForTransition: false,
                disableOnInteraction: true,
              }}
              modules={[Autoplay]}
            >
              {
                sliderClients.map((client, index) => {
                  return (
                  <SwiperSlide key={`agency-brand-${index}`}>
                    <div ref={el => images.current[index] = el} className="agency-clients__slider__item">
                      <img
                        className="agency-clients__slider__img"
                        src={client}
                        onLoad={() => setImagesLoaded(imagesLoaded + 1)}
                      />
                    </div>
                  </SwiperSlide>
                )})
              }
            </Swiper>
        </div>
        <div className="agency-clients__slider__separator agency-clients__slider__separator--bottom"></div>
      </div>      
    </div>
  )
}
export default AgencyClientsSlider
